<template lang="pug">
  AppLayout.page-dashboard

    div(class="duration-200 flex flex-col flex-grow items-center justify-center overflow-hidden py-24 relative transition-all", :style="firstLoad ? 'flex-grow: 0.001' : ''")
      img.dashboard-image(src="~@/assets/images/header-dashboard.jpeg" class="absolute inset-0 min-h-screen min-w-screen object-cover z-10")
      div(class="absolute bg-black opacity-25 inset-0 z-20")
      div.max-w-3xl.w-full.z-30
        h1.page-title(class="max-w-xl mb-10 mx-auto text-center text-white") Inspirational content from the world's leading experts, artists and creators
        TextInput.search(v-model="query.search", prepend-icon="search", icon-prefix="fas", placeholder="Where to?", @input="onSearch", :loading="loading", :spellcheck="false", auto-focus)

    div(ref="results", v-show="firstLoad")
      Band(name="results", centered, contained, :class="{'is-loading': loading}")

        div.search-loading(v-show="loading")
          div.search-loading__inner
            div.search-loading__icon: Icon(icon="spinner-third", prefix="fas", spin)
            div.search-loading__body Exploring...

        div.text-center.text-xl(v-show="resultsFetched && !items.length") Hmmm 🤔 No matches. Please try something else or click to chat with our Content Team.

        Row(lg="row", v-show="items.length")
          Column(class="lg:w-1/4", v-if="user.hasPermission('search.filters')")
            SearchFacet(v-for="(facet, index) in facetOrder", :key="index", :name="facet", :options="facets[facet]", v-model="query.filters[facet]", v-if="user.hasPermission('search.filters') && items.length && facets && facets[facet]", @input="onFacetChange()", :loading="loading")
          Column(class="lg:w-3/4")
            ContentHeader(item-label="result")
            Content(:items="items", :loading="loading", :show-controls="true")
            ContentPagination(:pagination="pagination", @page-changed="onPageChange")
</template>

<script>
import Content from "@/components/Content/Content"
import ContentHeader from "@/components/Content/ContentHeader"
import ContentPagination from "@/components/Content/ContentPagination"
import SearchFacet from "./components/SearchFacet"
import { debounce, get } from "lodash-es"
import { mapGetters } from "vuex"

export default {
  components: {
    Content,
    ContentHeader,
    ContentPagination,
    SearchFacet
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Search",
    loading: false,
    query: {
      search: "",
      page: 1,
      filters: {
        type: [],
        tags: [],
        depth: [],
        libraries: [],
        destinations: []
      }
    },
    items: [],
    facets: [],
    pagination: [],
    facetOrder: ["type", "tags", "depth", "libraries", "destinations"],
    firstLoad: false,
    resultsFetched: false
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    onFacetChange() {
      this.loading = true
      this.query.page = 1
      this.search()
    },
    onPageChange({ page }) {
      this.query.page = page
      this.search()
      this.$refs.results.scrollIntoView()
    },
    onSearch() {
      this.resultsFetched = false
      this.query.page = 1
      this.query.filters = { type: [], tags: [], depth: [], libraries: [], destinations: [] }
      this.search()
    },
    search: debounce(async function () {
      this.loading = true
      let { query } = this

      if (!query.search) {
        this.items = []
        this.loading = false
        return false
      }

      let response = await this.$api.post("search", {
        query: query.search,
        page: query.page - 1,
        filters: query.filters
      })

      this.items = response.data.hits
      this.facets = response.data.facets
      this.pagination = get(response, "data.meta", {})
      this.firstLoad = true
      this.resultsFetched = true
      this.loading = false
    }, 200)
  }
}
</script>

<style>
.page-dashboard {
  .page-title {
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
  }

  .dashboard-image {
    @apply h-screen w-screen;
  }

  .search {
    @apply rounded-full border-none px-2 shadow-xl;
    @apply md:px-3;
    height: 50px;
    line-height: 50px;

    @screen md {
      height: 60px;
      line-height: 60px;
    }

    .icon {
      @apply text-lg;
    }

    .form-control-input {
      @apply py-0 text-xl font-medium;
    }
  }

  .band-results .band-inner {
    min-height: 250px;
  }

  .search-loading {
    &__inner {
      @apply mx-auto flex flex-col items-center rounded-lg border bg-white p-12 italic shadow;
      max-width: 250px;
    }

    &__icon {
      @apply text-5xl text-primary-default;
    }

    &__body {
      @apply mt-6 font-semibold text-supplement;
    }
  }
}

@screen md {
  .page-dashboard {
    .content-header {
      @apply justify-end;
    }

    .band-results {
      .band-inner {
        @apply relative;
      }

      .search-loading {
        @apply absolute bottom-0 left-0 right-0 top-0 z-50;
      }
    }
  }
}
</style>
